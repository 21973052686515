import React, { FunctionComponent, lazy } from "react";
import AppNavigation from "@core/navigation";
import { RoutePath, ScreenNames } from "apps/salesguru/admin/src/navigation/types";
import { getLinking } from "./options";
import { useSelector } from "react-redux";
import { useDimensions } from "@core/store/dimensions/selecters";
import { View } from "react-native";
import { getHeight, getWidth } from "@core/utils";
import { useUserInformations } from "apps/salesguru/admin/src/hooks/user";
import { ENavigationType } from "@core/store/navigation/types";

const Home = lazy(() => import("../screens/home"));
const UserOnBoarding = lazy(() => import("../screens/onBoarding"));
const Activated = lazy(() => import("../screens/activated"));
const Wheels = lazy(() => import("../screens/wheels"));
const Wheel = lazy(() => import("../screens/wheel/index"));
const Managers = lazy(() => import("../screens/managers"));
const Manager = lazy(() => import("../screens/manager"));
const Gifts = lazy(() => import("../screens/gifts"));
const Gift = lazy(() => import("../screens/gift"));
const Codes = lazy(() => import("../screens/codes"));
const Code = lazy(() => import("../screens/code/index"));
const Earnings = lazy(() => import("../screens/earnings"));
const Email = lazy(() => import("../screens/email"));
const About = lazy(() => import("../screens/about"));

export const screens = [
  {
    name: ScreenNames.Home,
    component: Home,
    path: RoutePath.HOME_PATH,
    navigation: {
      type: ENavigationType.Stack,
    },
  },
  {
    name: ScreenNames.UserOnboarding,
    component: UserOnBoarding,
    path: RoutePath.USER_ON_BOARDING_PATH,
    navigation: {
      type: ENavigationType.Stack,
    },
  },
  {
    name: ScreenNames.Activated,
    component: Activated,
    path: RoutePath.ACTIVATED_PATH,
    navigation: {
      type: ENavigationType.Stack,
    },
  },
  {
    name: ScreenNames.Managers,
    component: Managers,
    path: RoutePath.MANAGERS_PATH,
  },
  {
    name: ScreenNames.Manager,
    component: Manager,
    path: RoutePath.MANAGER_PATH,
  },
  {
    name: ScreenNames.Wheels,
    component: Wheels,
    path: RoutePath.WHEELS_PATH,
    navigation: {
      type: ENavigationType.Tab,
    },
  },
  {
    name: ScreenNames.Wheel,
    component: Wheel,
    path: RoutePath.WHEEL_PATH,
    navigation: {
      type: ENavigationType.Tab,
      parent: ScreenNames.Wheels,
    },
  },
  {
    name: ScreenNames.Gifts,
    component: Gifts,
    path: RoutePath.GIFTS_PATH,
    navigation: {
      type: ENavigationType.Tab,
      parent: ScreenNames.Wheel,
    },
  },
  {
    name: ScreenNames.Gift,
    component: Gift,
    path: RoutePath.GIFT_PATH,
    navigation: {
      type: ENavigationType.Tab,
      parent: ScreenNames.Gift,
    },
  },
  {
    name: ScreenNames.Codes,
    component: Codes,
    path: RoutePath.CODES_PATH,
    navigation: {
      type: ENavigationType.Tab,
      parent: ScreenNames.Wheel,
    },
  },
  {
    name: ScreenNames.Code,
    component: Code,
    path: RoutePath.CODE_PATH,
    navigation: {
      type: ENavigationType.Tab,
      parent: ScreenNames.Codes,
    },
  },
  {
    name: ScreenNames.Earnings,
    component: Earnings,
    path: RoutePath.EARNINGS_PATH,
    navigation: {
      type: ENavigationType.Tab,
      parent: ScreenNames.Wheel,
    },
  },
  {
    name: ScreenNames.Email,
    component: Email,
    path: RoutePath.EMAIL_PATH,
    navigation: {
      type: ENavigationType.Tab,
      parent: ScreenNames.Wheel,
    },
  },
  {
    name: ScreenNames.About,
    component: About,
    path: RoutePath.ABOUT_PATH,
    navigation: {
      type: ENavigationType.Tab,
      parent: ScreenNames.Wheel,
    },
  },
];

interface INavigation {}

const Navigation: FunctionComponent<INavigation> = () => {
  const dimensions = useSelector(useDimensions);

  return (
    <View
      style={{
        flex: 1,
        width: getWidth(),
        height: getHeight(),
        overflow: "hidden",
      }}
    >
      <AppNavigation
        getLinking={getLinking}
        customHook={useUserInformations}
        defaultScreen={ScreenNames.Home}
        initScreens={screens}
      />
    </View>
  );
};

export default Navigation;
