import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceID } from "@core/utils/device";
import {
  updateUserDeviceId,
  updateUserCodeVerificationSended,
  updateUserActivated,
  updateUserCode,
  updateUserAccessToken,
} from "@core/store/user/actions";
import { useUser } from "@core/store/user/selecters";
import { useNotification } from "@core/hooks/notifications";
import { fetchRequest, IFetchResponseError } from "@core/utils/request";
import { API_URL } from "@core/config";
export const OPENDO_USER_COOKIE_NAME: string = "OpendoUserCookie";
//one week expiration cookie
export const OPENDO_USER_COOKIE_EXPIRE: number = 604800;
export const OPENDO_USER_COOKIE_DOMAIN: string = "opendo.fr";


interface IActivationRequestResponse {
  result: string | null;
  message: string | null;
  data: {
    email: string;
    enabled: number;
    free: boolean;
  };
  token:string;
}

enum ResponseResultType {
  SUCCESS = "success",
  ERROR = "error",
}

enum ResponseMessageType {
  CODE_GENERATED = "code generated",
  TOO_MANY_DEVICES = "too many devices",
  EMAIL_NOT_FOUND = "email not found",
  CODE_ACCEPTED = "code accepted",
  CODE_NOT_VALID = "code not valid",
}


export const useUserInformations = () => {
  const dispatch = useDispatch();
  const { checkActivation } = useUserFetchRequest();
  useEffect(() => {
    const deviceId = getDeviceID();
    dispatch(updateUserDeviceId(deviceId));
    (async () => {
      await checkActivation(false);
    })();
  }, []);
};

export const useUserFetchRequest = () => {
  const dispatch = useDispatch();
  const user = useSelector(useUser);
  const {
    displayWarningNotification,
    displaySuccessNotification,
    displayErrorNotification,
  } = useNotification();

  const generateAndSendCode = async (): Promise<boolean> => {
    const { userDeviceId, userEmail } = user;
    const body = JSON.stringify({
      deviceID: userDeviceId,
      email: userEmail,
    });

    const response = await fetchRequest<IActivationRequestResponse>({
      endpoint: "auth/generateCode",
      method: "POST",
      body,
      path:API_URL,
      bodyEncoded:true,
    }, null);


    if (response == <IActivationRequestResponse>response) {
      if (response.result == ResponseResultType.SUCCESS) {
        if(response.data.enabled != 1){
          displayWarningNotification({
            key: "api.warning.cantActivate"
          });
          return false;
        }
        switch (response.message) {
          case ResponseMessageType.CODE_GENERATED:
          case ResponseMessageType.TOO_MANY_DEVICES:
            displaySuccessNotification({
              key: "api.success.codeGenerated",
            });
            dispatch(updateUserCode(null))
            dispatch(updateUserCodeVerificationSended(true));
            return true;
            break;
          default:
            displayErrorNotification({
              key: "api.error.unknownError",
            });
            break;
        }
      }
      if (response.result == ResponseResultType.ERROR) {
        switch (response.message) {
          case ResponseMessageType.EMAIL_NOT_FOUND:
            displayWarningNotification({
              key: "api.warning.emailNotFound",
            });
            break;
          default:
            displayErrorNotification({
              key: "api.error.unknownError",
            });
            break;
        }
      }
    }
    if (response == <IFetchResponseError>response) {
      /*displayWarningNotification({
        key: "api.error.unknownError",
      });*/
    }
    return false;
  };

  const checkCodeAndActivate = async (): Promise<boolean> => {
    const { userDeviceId, userEmail, userCode } = user;
    const body = JSON.stringify({
      deviceID: userDeviceId,
      email: userEmail,
      code: userCode,
    });

    const response = await fetchRequest<IActivationRequestResponse>({
      endpoint: "auth/loginWithCode",
      method: "POST",
      body,
      path:API_URL,
      bodyEncoded:true
    });

    if (response == <IActivationRequestResponse>response) {
      if (response.result == ResponseResultType.SUCCESS) {
        switch (response.message) {
          case ResponseMessageType.CODE_ACCEPTED:
            const { token } = response;
            const { enabled } = response.data;
            displaySuccessNotification({
              key: "api.success.activated",
            });
            dispatch(updateUserAccessToken(token));
            dispatch(updateUserActivated(true));
           
            return true;

            break;
          default:
            displayErrorNotification({
              key: "api.error.unknownError",
            });
            break;
        }
      }
      if (response.result == ResponseResultType.ERROR) {
        switch (response.message) {
          case ResponseMessageType.CODE_NOT_VALID:
            displayWarningNotification({
              key: "api.warning.codeNotValid",
            });

            break;
          default:
            displayErrorNotification({
              key: "api.error.unknownError",
            });
            break;
        }
      }
    }
    if (response == <IFetchResponseError>response) {
      /*displayWarningNotification({
        key: "api.error.unknownError",
      });*/
    }
    return false;
  };

  const checkActivation = async (displayNotification:boolean = true) => {
    const { userDeviceId, userEmail } = user;
    const body = JSON.stringify({
      deviceID: userDeviceId,
      email: userEmail,
    });

    const response = await fetchRequest<IActivationRequestResponse>({
      endpoint: "auth/isActivated",
      method: "POST",
      path:API_URL,
      body,
      bodyEncoded:true
    });

    if (response == <IActivationRequestResponse>response) {
      if (response.result == ResponseResultType.SUCCESS) {
        const { enabled, free } = response.data;
        const activated = enabled == 1 || free;
        if (activated && displayNotification) {
          displaySuccessNotification({
            key: "api.success.activated",
          });
        }
        dispatch(updateUserActivated(activated));
      } else {
        if(displayNotification){
          displayWarningNotification({
            key: "api.error.desactivated",
          });
        }
        dispatch(updateUserActivated(false));
      }
    }
    if (response == <IFetchResponseError>response) {
      /*displayWarningNotification({
        key: "api.error.unknownError",
      });*/
    }
  };

  return {
    generateAndSendCode,
    checkCodeAndActivate,
    checkActivation,
  };
};

