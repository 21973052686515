import { RootState } from "@core/store";
import { EThemeType } from "./types";

export const useTheme = (state: RootState) => state.theme;

export const useGetThemeData =
  (name: EThemeType = EThemeType.Light) =>
  (state: RootState) => {
    const { theme } = state;
    if (!theme) {
      return null;
    }
    const selected = theme.themes.filter((theme) => theme.name == name);
    return selected.length > 0 ? selected[0] : null;
  };

export const useGetCurrentThemeData = (state: RootState) => {
  const { theme } = state;
  if (!theme) {
    return null;
  }
  const selected = theme.themes.filter((t) => t.name == theme.name);
  return selected.length > 0 ? selected[0] : null;
};
