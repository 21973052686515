
import { ScreenNames } from "apps/salesguru/admin/src/navigation/types";

export default {
  translation: {
    home: {
      buttonText: "Démarrer",
      buttonReconnectingText: "Reconnexion à la session ...",
    },
    module:{
      userOnBoarding:{
        profileInfos: {
          title: "Connectez-vous",
        },
      }
    },
    pages:{
      [ScreenNames.Activated]:{
        title:"Votre abonnement est activé"
      },
      [ScreenNames.Wheels]:{
        title:"Mes roues de la fortune",
      },
      [ScreenNames.Wheel]:{
        title:"Roue de la fortune",
        defaultName:"MA ROUE",
        blockWin:{
          text_one:"{{earnings}} gain gagné",
          text_other:"{{earnings}} gains gagnés"
        },
        blockBudget:{
          text:"{{value}}€ / {{total}}€",
        }
      },
      [ScreenNames.Managers]:{
        title:"Managers",
        buttonAdd:"Ajouter un manager",
        list:{
          title:"Managers",
        }
      },
      [ScreenNames.Manager]:{
        blockImageTitle:'Image profile',
        blockInfoTitle:'Informations Manager',
        inputFirstName:'Prénom',
        inputLastName:'Nom',
        inputEmail:'Email'
      },
      [ScreenNames.Gifts]:{
        title:"Lots",
        buttonAdd:"Ajouter un lot",
        list:{
          title:"Lots",
          wons:"{{wons}}/{{total}} gagné"
        }
      },
      [ScreenNames.Codes]:{
        title:"Codes",
        buttonAdd:"Ajouter un code",
        list:{
          title:"Codes"
        }
      },
      [ScreenNames.Earnings]:{
        title:"Historique de gains",
        list:{
          title:"Historique de gains"
        }
      },
      [ScreenNames.Email]:{
        title:"Editer les textes des emails"
      },
      [ScreenNames.About]:{
        title:"A propos de Sales Guru by Opendo"
      }
    },
    screensTitles: {
      [ScreenNames.Home]: "Accueil",
      [ScreenNames.UserOnboarding]: "Connexion",
      [ScreenNames.Wheels]: "Roues",
      [ScreenNames.Wheel]: "Roue",
      [ScreenNames.Managers]: "Managers",
      [ScreenNames.Manager]: "Manager",
      [ScreenNames.Gifts]: "Lots",
      [ScreenNames.Gift]: "Lot",
      [ScreenNames.Codes]: "Codes",
      [ScreenNames.Code]: "Code",
      [ScreenNames.Earnings]: "Gains",
      [ScreenNames.Email]: "Email",
      [ScreenNames.About]: "A propos",
    },
  },
};