import { StackNavigationProp } from '@react-navigation/stack/lib/typescript/src/types';
import { RouteProp } from '@react-navigation/native';

import {
  RoutePath as CoreRoutePath,
  ScreenNames as CoreScreenNames,
  RootStackParamList as CoreRootStackParamList,
} from '@core/navigation/types';

export enum AppRoutePath {
  WHEELS_PATH = "wheels",
  MANAGERS_PATH = "managers/:id",
  GIFTS_PATH = "gifts/:id",
  CODES_PATH = "codes/:id",
  EARNINGS_PATH =  "earnings/:id",
  EMAIL_PATH = "email",
  ABOUT_PATH = "about",
  WHEEL_PATH = "wheel/:id", 
  MANAGER_PATH = "manager/:id",
  GIFT_PATH = "gift/:id",
  CODE_PATH = "code/:id",
  LOGGED_IN_PATH = "wheels"
}

export const RoutePath = { ...AppRoutePath, ...CoreRoutePath };

export enum AppScreenNames {
  Wheels = "Mes roues",
  Wheel = "Roue",
  Managers = "Managers",
  Manager = "Manager",
  Gifts = "Lots",
  Gift = "Lot",
  Codes = "Codes",
  Code = "Code",
  Earnings = "Gains",
  Email = "Email",
  About = "A propos",
  LoggedIn = "Wheels"
}

export const ScreenNames = { ...AppScreenNames, ...CoreScreenNames };

export type AppRootStackParamList = {
  [ScreenNames.Code]: undefined | { id: string };
  [ScreenNames.Managers]: undefined;
  [ScreenNames.Wheels]: undefined;
  [ScreenNames.Gifts]: undefined;
  [ScreenNames.Codes]: undefined;
  [ScreenNames.Earnings]: undefined;
  [ScreenNames.Wheel]: undefined | { id: string };
  [ScreenNames.Manager]: undefined | { id: string };
  [ScreenNames.Gift]: undefined | { id: string };
  [ScreenNames.Code]: undefined | { id: string };
  [ScreenNames.Email]: undefined;
};

export type RootStackParamList = AppRootStackParamList & CoreRootStackParamList;


export type RootStackComponent<RouteName extends keyof RootStackParamList> = {
  navigation: StackNavigationProp<RootStackParamList, RouteName>;
  route: RouteProp<RootStackParamList, RouteName>;
};

