import * as React from "react";
import Svg, { Path, G, Circle } from "react-native-svg";
import { FunctionComponent } from "react";
import { ISvgIcons } from "@core/components/Svg/types";
import { colors } from "@core/utils/colors";

export const IconEarnings: FunctionComponent<ISvgIcons> = ({
  size = 24,
  color = colors.white,
  stroke = colors.lightRed,
}) => {
  return (
    <Svg width={size} height={size} viewBox="-55 -45 240 220">
      <G data-name="Groupe 1176">
        <G data-name="Groupe 1171">
          <Path
            data-name="Trac\xE9 846"
            d="M8.737 72.066a3 3 0 0 1-.948-5.847l35.394-11.8 35.529-32.567a3.073 3.073 0 0 1 .873-.558l36-15a3 3 0 0 1 2.31 5.538l-35.514 14.8-35.613 32.645a3.013 3.013 0 0 1-1.077.633l-36 12a2.966 2.966 0 0 1-.954.156Z"
            fill={color}
          />
        </G>
        <G data-name="Groupe 1172" transform="translate(0 60)">
          <Circle data-name="Ellipse 162" cx={9} cy={9} r={9} fill={color} />
        </G>
        <G data-name="Groupe 1173" transform="translate(36 48)">
          <Circle data-name="Ellipse 163" cx={9} cy={9} r={9} fill={color} />
        </G>
        <G data-name="Groupe 1174" transform="translate(72 15)">
          <Circle data-name="Ellipse 164" cx={9} cy={9} r={9} fill={color} />
        </G>
        <G data-name="Groupe 1175" transform="translate(108)">
          <Circle data-name="Ellipse 165" cx={9} cy={9} r={9} fill={color} />
        </G>
      </G>
      <G data-name="Groupe 1181">
        <G data-name="Groupe 1177">
          <Path
            data-name="Trac\xE9 847"
            d="M45 120a6 6 0 0 1-6-6V78.066a6 6 0 0 1 12 0V114a6 6 0 0 1-6 6Z"
            fill={color}
          />
        </G>
        <G data-name="Groupe 1178">
          <Path
            data-name="Trac\xE9 848"
            d="M9 120a6 6 0 0 1-6-6V90.066a6 6 0 0 1 12 0V114a6 6 0 0 1-6 6Z"
            fill={color}
          />
        </G>
        <G data-name="Groupe 1179">
          <Path
            data-name="Trac\xE9 849"
            d="M81 120a6 6 0 0 1-6-6V45a6 6 0 0 1 12 0v69a6 6 0 0 1-6 6Z"
            fill={color}
          />
        </G>
        <G data-name="Groupe 1180">
          <Path
            data-name="Trac\xE9 850"
            d="M117 120a6 6 0 0 1-6-6V30.066a6 6 0 0 1 12 0V114a6 6 0 0 1-6 6Z"
            fill={color}
          />
        </G>
      </G>
    </Svg>
  );
};
