import { StyleSheet } from "react-native";
import { colors, convertHexToRGBA } from "../../utils/colors";
import { Fonts } from "../../utils/fonts";
import {
  FontSizeType,
  responsiveStyles,
  MQType,
  responsiveFontSize,
  responsiveMargin,
  MQSize,
  responsivePadding,
} from "../../utils/mq";
import { Roboto_400Regular } from "@expo-google-fonts/roboto";
import { getWidth } from "../../utils";
import { MODAL_PADDING } from "./utils";
import { ModalType } from "./types";
import { Modal } from "react-native-paper";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100%",
  },
});
export default styles;

export const modalRootStyles = () => {
  return StyleSheet.create({
    container: {
      flex: 1,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  });
};

export const modalHeaderStyles = () => {
  return StyleSheet.create({
    container: {
      backgroundColor: convertHexToRGBA(colors.extraLightGrey, 1),
      padding: MODAL_PADDING,
      flexDirection: "row-reverse",
      alignItems: "stretch",
      justifyContent: "space-between",
      width: "100%",
    },
    closeButton: {},
  });
};

export const modalFooterStyles = () => {
  return StyleSheet.create({
    container: {
      backgroundColor: convertHexToRGBA(colors.extraLightGrey, 1),
      padding: MODAL_PADDING,
      flexDirection: "row-reverse",
      alignItems: "stretch",
      justifyContent: "space-between",
      width: "100%",
    },
  });
};

export const modalFactoryStyles = () => {
  return StyleSheet.create({
    container: {
      flex: 1,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  });
};

export const modalWrapperStyles = (
  modalType: ModalType = ModalType.BOTTOM,
  hasBackground: boolean = true,
  hasPadding: boolean
) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: hasBackground ? colors.white : "transparent",
      ...responsiveStyles([
        {
          mq: [MQType.SM],
          value: {
            padding: hasPadding ? "1.5em" : 0,
          },
        },
        {
          mq: [MQType.LG],
          value: {
            padding: hasPadding ? "2.5em" : 0,
          },
        },
      ]),
    },
    innerContainer: {
      flex: 1,
      borderTopLeftRadius: modalType == ModalType.FULLSCREEN ? 0 : 20,
      borderTopRightRadius: modalType == ModalType.FULLSCREEN ? 0 : 20,
      paddingVertical: hasPadding ? responsivePadding(MQSize.MEDIUM) : 0,
      paddingHorizontal: hasPadding ? MODAL_PADDING : 0,
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      height: "100%",
    },
    titleContainer: {
      marginBottom: responsiveMargin(MQSize.SMALL),
      maxWidth: getWidth() * 0.7,
    },
    title: {
      fontSize: responsiveFontSize(FontSizeType.MEDIUM),
      fontFamily: Fonts.ROBOTO_REGULAR,
      color: colors.white,
      textAlign: "center",
      width: "100%",
    },
    description: {
      fontSize: responsiveFontSize(FontSizeType.SMALL),
      fontFamily: Fonts.ROBOTO_THIN,
      color: colors.white,
      textAlign: "center",
      width: "100%",
    },
    tip: {
      fontSize: responsiveFontSize(FontSizeType.SMALL),
      fontFamily: Fonts.ROBOTO_THIN,
      color: colors.white,
      textAlign: "center",
      width: "100%",
      marginTop: 10,
    },
    closeButton: {
      position: "absolute",
      top: MODAL_PADDING,
      right: MODAL_PADDING,
      zIndex:10
    },
    blurView: {
      flex: 1,
      borderTopLeftRadius: modalType == ModalType.BOTTOM ? 20 : 0,
      borderTopRightRadius: modalType == ModalType.BOTTOM ? 20 : 0,
      height: "100%",
    },
  });
};
