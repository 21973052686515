import { IModalProps, ModalType } from "./types";
import {
    IModalState,
    ModalsActionTypes,
    SHOW_MODAL, 
    HIDE_MODAL, 
    REMOVE_MODAL,
    RESET_MODALS
} from "./types";

export function showModal(
    modalName:string,
    modalProps?:IModalProps,
): ModalsActionTypes {
    return {
        type: SHOW_MODAL,
        payload: { modalName, modalProps },
    };
}

export function hideModal(
    modalName: string
): ModalsActionTypes {
    return {
        type: HIDE_MODAL,
        payload: { modalName },
    };
}


export function removeModal(
    modalName: string
): ModalsActionTypes {
    return {
        type: REMOVE_MODAL,
        payload: { modalName },
    };
}


export function resetModals():ModalsActionTypes {
    return {
        type: RESET_MODALS
    };
}
  