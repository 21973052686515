import { useDispatch } from "react-redux";
import {
  errorNotification,
  successNotification,
  warningNotification,
} from "../../store/inAppNotification/actions";
import { TFunction, useTranslation } from "react-i18next";
import { AppDispatch } from "../../store";

interface INotificationParams {
  key?: string;
  title?: string | null;
  description?: string | null;
  duration?:number;
}

export enum NotificationType {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
}

export const notifications = (dispatch: AppDispatch, t: TFunction) => {
  const getValuesFromParams = (params: INotificationParams) => {
    let _title,
      _description = "";
    const { key, title, description, duration } = params;
    _title = title || "";
    _description = description || "";
    if (key) {
      _title = t(`${key}.title`);
      _description = t(`${key}.description`);
    }
    return {
      title: _title,
      description: _description,
      duration
    };
  };

  const displaySuccessNotification = async (params: INotificationParams) => {
    const { title, description, duration } = getValuesFromParams(params);
    dispatch(
      successNotification({
        title,
        description,
        duration
      })
    );
  };

  const displayWarningNotification = async (params: INotificationParams) => {
    const { title, description, duration } = getValuesFromParams(params);
    dispatch(
      warningNotification({
        title,
        description,
        duration
      })
    );
  };

  const displayErrorNotification = async (params: INotificationParams) => {
    const { title, description, duration } = getValuesFromParams(params);
    dispatch(
      errorNotification({
        title,
        description,
        duration
      })
    );
  };

  return {
    displaySuccessNotification,
    displayWarningNotification,
    displayErrorNotification,
  };
};

export const useNotification = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return notifications(dispatch, t);
};
