

export interface IModalRoot {
  appModalsComponents?: any;
}

export interface IModalFactory {
  modalComponents: any;
}

export interface IModalWrapper {
  title?: string;
  tip?: string;
  hasButtonClose?: boolean;
  hasBackground?: boolean;
  hasPanGesture?: boolean;
  hasPadding?: boolean;
  hasHeader?: boolean;
  hasFooter?: boolean;
  headerComponent?: () => JSX.Element | undefined;
  footerComponent?: () => JSX.Element | undefined;
}

export interface IModalProps {
  [key: string]: any;
}

export interface IModal {
  title?: string;
  tip?: string;
  modalName: string;
  modalType: ModalType;
  modalConfig: { level: number; autoclose: boolean };
  onOpen?: () => void;
  onClose?: () => void;
}

export interface IModalRef {
  open: () => void;
  close: () => void;
}

export enum ModalType {
  BOTTOM = "bottom",
  POPUP = "popup",
  FULLSCREEN = "fullscreen",
  STANDALONE = "standalone",
}
