import { IconWheel } from "./IconWheel";
import { IconGift } from "./IconGift";
import { IconCodes } from "./IconCodes";
import { IconEarnings } from "./IconGains";
export const AppIconsList = {
  Gift: IconGift,
  Codes: IconCodes,
  Earnings: IconEarnings,
  Wheel:IconWheel
};
