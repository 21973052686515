import { navigationRef } from "../../navigation";
import { useFocusEffect, useRoute, useIsFocused } from '@react-navigation/native';
import { useCallback, useEffect, useState } from "react";
import { isNativeError } from "util/types";
import { ScreenNames } from "@app/navigation/types";

export function navigate(name: ScreenNames, options: any = {}) {
  if (navigationRef.current?.isReady()) {
    navigationRef.current.navigate(name as never, options);
  }
}

export const useNavigation = () => {
  const route = useRoute();
  const [routeParamId, setRouteParamId] = useState<string | null>(null);
  const isFocused = useIsFocused();

  useEffect(() => {
    if(isFocused){
      setRouteParamId(route.params?.id || null);
    }
  }, [isFocused, route.params?.id])
  


  const navigateTo = (screen: ScreenNames | undefined, params: any = {}, ) => {
    if(!screen) return;
    navigate(screen, params);
  };

  return {
    navigation:navigationRef.current,
    isReady:navigationRef.current?.isReady(),
    navigateTo,
    route,
    routeParamId
  };
};
