import React, { FunctionComponent, useState, useEffect } from "react";
import { Text, StyleSheet, TouchableOpacity, ViewStyle } from "react-native";
import { SVGIcon } from "../Svg";
import { colors } from "../../utils/colors";
import { MQSize, FontSizeType, responsiveFontSize } from "../../utils/mq";
import { Fonts } from "../../utils/fonts";

interface ICheckBox {
  label?: string;
  value?: number;
  isChecked?: boolean;
  size?: MQSize | number;
  textSize?: FontSizeType;
  onValueChange?: Function;
  font:Fonts;
  style?: ViewStyle;
}

const Checkbox: FunctionComponent<ICheckBox> = ({
  label,
  value,
  isChecked = false,
  size = MQSize.MEDIUM,
  textSize = FontSizeType.SMALL,
  onValueChange,
  font = Fonts.ROBOTO_BOLD,
  style,
}) => {
  const styles = checkboxStyles(textSize, font);
  const [checked, setChecked] = useState<boolean>(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <>
      <TouchableOpacity
        style={[styles.checkbox, style]}
        onPress={() => {
          setChecked(!checked);
          if (onValueChange) {
            onValueChange(!checked, value);
          }
        }}
      >
        {!checked && <SVGIcon name={"CheckBox"} size={size} background={false} />}

        {checked && <SVGIcon name={"CheckBoxChecked"} size={size}  background={false} />}

        {label != "" && <Text style={styles.label}>{label}</Text>}
      </TouchableOpacity>
    </>
  );
};

const checkboxStyles = (size: FontSizeType, font:Fonts = Fonts.ROBOTO_BOLD) => {
  return StyleSheet.create({
    checkbox: {
      alignItems: "center",
      flexDirection: "row",
    },
    label: {
      textAlign: "left",
      marginLeft: 10,
      color: colors.white,
      fontFamily: font,
      fontSize: responsiveFontSize(size),
    },
  });
};

export default Checkbox;
