import { useCallback, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IModalRef } from "../../components/Modals/types";
import { AppDispatch } from "../../store";
import { hideModal } from "../../store/modals/actions";
import { useModals } from "../../store/modals/selecters";

export const useModal = (ref: any = null) => {
  const dispatch = useDispatch<AppDispatch>();
  const modalRef = useRef<IModalRef>();
  const modals = useSelector(useModals);
  const open = () => {
    modalRef.current?.open();
  };
  const close = () => {
    modalRef.current?.close();
  };

  if (ref != null) {
    useImperativeHandle(ref, () => ({
      open,
      close,
    }));
  }

  const closeAllModals = useCallback(() => {
    modals.activeModals.forEach((modal) => {
      dispatch(hideModal(modal.modalName));
    })
  }, [dispatch, modals]);

  return { modalRef, open, close, closeAllModals };
};
