import * as React from "react";
import Svg, { Path, G } from "react-native-svg";
import { FunctionComponent } from "react";
import { ISvgIcons } from "@core/components/Svg/types";
import { colors } from "@core/utils/colors";

export const IconWheel: FunctionComponent<ISvgIcons> = ({
  size = 24,
  color = colors.white,
  stroke = colors.lightRed,
}) => {
  return (
    <Svg width={size} height={size} viewBox="-100 -100 1200 1200">
      <Path fill={color} d="M500 990C229.4 990 10 770.6 10 500S229.4 10 500 10s490 219.4 490 490-219.4 490-490 490zm315.1-805.1L500 500V54.4c-122.4 0-233.2 49.4-313.7 129.2L500 500H54.5c0 122.4 49.4 233.1 129.2 313.7L500 500v445.5c122.3 0 233.1-49.3 313.7-129.2L500 500h445.6c0-123.1-49.9-234.4-130.5-315.1z" />
 </Svg>
  );
};
