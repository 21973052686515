import React, { FunctionComponent } from "react";
import { View, Text } from "react-native";
import { IModal, ModalType } from "@core/components/Modals/types";
import ModalWrapper from "@core/components/Modals/ModalWrapper";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styles from "./styles";
import { CustomButton } from "@core/components";
import { colors } from "@core/utils/colors";
import { MQSize } from "@core/utils/mq";
import { Fonts } from "@core/utils/fonts";
import { ScreenNames } from "apps/salesguru/admin/src//navigation/types";
import { hideModal } from "@core/store/modals/actions";
import { navigate } from "@core/hooks/navigation";
import { updateAppProcessing } from "@core/store/app/action";


interface IModalWheelsOptions extends IModal {
  onAddWheel?:() => void;
}

const ModalWheelsOptions: FunctionComponent<IModalWheelsOptions> = ({
  title,
  tip,
  modalName,
  modalConfig,
  onAddWheel
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();


  return (
    <ModalWrapper
      title={title}
      tip={tip}
      modalName={modalName}
      modalType={ModalType.BOTTOM}
      modalConfig={modalConfig}
      hasBackground={false}
      hasButtonClose={true}
      hasPadding={true}
    >
      <View style={styles.aligner}>

        <CustomButton 
          icon={{
            name:'Add',
            background:true,
            backgroundColor:colors.lightBlue,
          }}
          size={MQSize.MEDIUM}
          style={styles.spacer}
          font={Fonts.ROBOTO_THIN}
          title={'Créer une roue'}
          background={false}
          onPress={async () => {
            dispatch(hideModal('MODAL_WHEELS_OPTIONS'));
            onAddWheel && onAddWheel()
           
          }}
        />
       <CustomButton 
          icon={{
            name:'Send',
            background:true,
            backgroundColor:colors.lightBlue
          }}
          size={MQSize.MEDIUM}
          style={styles.spacer}
          font={Fonts.ROBOTO_THIN}
          title={'Éditer les textes des emails'}
          background={false}
          onPress={() => {
            dispatch(hideModal('MODAL_WHEELS_OPTIONS'));
            navigate(ScreenNames.Email)
          }}
        />
        <CustomButton 
          icon={{
            name:'Question',
            background:true,
            backgroundColor:colors.lightBlue
          }}
          size={MQSize.MEDIUM}
          style={styles.spacer}
          font={Fonts.ROBOTO_THIN}
          title={'A propos de Sales Guru by Opendo'}
          background={false}
          onPress={() => {
            dispatch(hideModal('MODAL_WHEELS_OPTIONS'));
            navigate(ScreenNames.About)
          }}
        />
       
      </View>
    </ModalWrapper>
  );
};

export default ModalWheelsOptions;
