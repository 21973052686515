import { AppCoreActionTypes, createAppStore } from '@core/store';
import { ThunkDispatch } from 'redux-thunk';

const appReducers = {
};

export const store = createAppStore(appReducers);

export { appReducers };

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = ThunkDispatch<
  RootState,
  unknown,
  AppCoreActionTypes
>;

export type AppActionTypes = null;
