import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { View, Text } from "react-native";
import {
  modalFooterStyles,
  modalHeaderStyles,
  modalRootStyles,
  modalWrapperStyles,
} from "./styles";
import { useDimensions } from "../../store/dimensions/selecters";
import { useDispatch, useSelector } from "react-redux";
import { IModal, IModalWrapper, ModalType } from "./types";
import CustomButton from "../CustomButton";
import { Portal } from "react-native-paper";
import { Modalize } from "react-native-modalize";
import { useModal } from "../../hooks/modal";
import { colors, convertHexToRGBA } from "../../utils/colors";
import { BlurView } from "expo-blur";
import Animated from "react-native-reanimated";
import { MQSize } from "../../utils/mq";
import { AppDispatch } from "../../store";
import { hideModal, removeModal } from "../../store/modals/actions";
import { getModal } from "../../store/modals/selecters";
import { getHeight } from "../../utils";
import { useGetCurrentThemeData } from "../../store/theme/selecters";

const ModalWrapper: FunctionComponent<IModal & IModalWrapper> = ({
  title,
  tip,
  children,
  modalName,
  modalType,
  onClose,
  hasBackground = true,
  hasButtonClose = true,
  hasPadding = true,
  hasPanGesture = true,
  hasHeader = false,
  hasFooter = false,
  headerComponent,
  footerComponent,
}) => {
  const theme = useSelector(useGetCurrentThemeData);
  const styles = modalWrapperStyles(modalType, hasBackground, hasPadding);
  const headerStyles = modalHeaderStyles();
  const footerStyles = modalFooterStyles();
  const dimensions = useSelector(useDimensions);
  const dispatch = useDispatch<AppDispatch>();
  const { modalRef, open, close } = useModal();
  const modal = useSelector(getModal(modalName));
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  let timer: ReturnType<typeof setTimeout> | null = null;
  const scrollY = useRef<number>(0);

  const openModal = () => {
    setIsOpen(true);
    if (modalType == ModalType.BOTTOM || modalType == ModalType.FULLSCREEN) {
      open();
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    if (modalType == ModalType.BOTTOM || modalType == ModalType.FULLSCREEN) {
      close();
    }
  };

  const renderModalContent = () => {
    return (
      <Animated.View style={styles.innerContainer}>
        {!hasHeader && hasButtonClose && (
          <CustomButton
            style={styles.closeButton}
            icon={{
              name: "Close",
              size: MQSize.SMALL,
              backgroundColor:theme?.buttonColor
            }}
            background={false}
            onPress={() => {
              dispatch(hideModal(modalName));
            }}
          />
        )}
        {title && (
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{title}</Text>
            {title && tip && <Text style={styles.tip}>{tip}</Text>}
          </View>
        )}

        {children}
      </Animated.View>
    );
  };

  const renderHeader = () => {
    if (!(headerComponent && headerComponent()) && !hasHeader) {
      return undefined;
    }

    return (
      <View style={headerStyles.container}>
        {hasButtonClose && (
          <CustomButton
            style={headerStyles.closeButton}
            icon={{
              name: "Close",
              size: MQSize.SMALL,
            }}
            background={false}
            onPress={() => {
              dispatch(hideModal(modalName));
            }}
          />
        )}
        {headerComponent && headerComponent()}
      </View>
    );
  };

  const renderFooter = () => {
    if (!(footerComponent && footerComponent()) || !hasFooter) {
      return undefined;
    }
    return <View style={footerStyles.container}>{footerComponent()}</View>;
  };

  useEffect(() => {
    setTimeout(() => {
      openModal();
    }, 0);
  }, []);

  useEffect(() => {
    if (!modal?.display) {
      closeModal();
    }
  }, [modal?.display]);

  return (
    <Portal>
      <View style={{ flex: 1 }}>
        {modalType == ModalType.STANDALONE && isOpen && renderModalContent()}
        {(modalType == ModalType.BOTTOM ||
          modalType == ModalType.FULLSCREEN) && (
          <Modalize
            ref={modalRef}
            adjustToContentHeight={
              modalType != ModalType.FULLSCREEN ? true : undefined
            }
            modalHeight={
              modalType == ModalType.FULLSCREEN ? getHeight() : undefined
            }
            panGestureEnabled={hasPanGesture && !isScrolling}
            withHandle={hasPanGesture}
            rootStyle={{
              overflow: "hidden",
              height: "100%",
            }}
            overlayStyle={{
              backgroundColor: convertHexToRGBA(colors.darkBlue, 0.5),
            }}
            modalStyle={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              backgroundColor: "transparent",
              height: "100%",
            }}
            childrenStyle={{
              height: "100%",
            }}
            handlePosition={modalType == ModalType.FULLSCREEN ? "inside" : "outside"}
            handleStyle={{
              backgroundColor:convertHexToRGBA(colors.white, .5)
            }}
            scrollViewProps={{
              contentContainerStyle: {
                minHeight: "100%",
              },
              onScroll: ({
                nativeEvent: {
                  contentOffset: { x, y },
                },
              }) => {
                setIsScrolling(true);
                if (timer != null) {
                  clearTimeout(timer);
                  timer = null;
                }
                timer = setTimeout(() => {
                  setIsScrolling(false);
                  timer = null;
                }, 200);
                scrollY.current = y;
              },
            }}
            HeaderComponent={renderHeader()}
            FooterComponent={renderFooter()}
            onClosed={() => {
              onClose && onClose();
              dispatch(removeModal(modalName));
            }}
            onOpen={() => {}}
          >
            <BlurView intensity={60} tint="dark" style={styles.blurView}>
              {renderModalContent()}
            </BlurView>
          </Modalize>
        )}
      </View>
    </Portal>
  );
};

export default ModalWrapper;
