import { RootState } from "../index";
import { createSelector } from "reselect";
import { IModalsState, IModalState } from "./types";
import { filter } from "lodash";
import { Selector } from "../index";

export const useModals = (state: RootState) => state.modals;

export const getModal = (modalName: string): Selector<IModalState | null> => {
  return createSelector(useModals, (modals: IModalsState) => {
    const { activeModals } = modals;
    const existModal = filter(
      activeModals,
      (modal: IModalState) => modal.modalName == modalName
    );
    if (existModal.length) {
      return existModal[0];
    }
    return null;
  });
};

export const hasActiveModal = (): Selector<boolean> => {
  return createSelector(useModals, (modals: IModalsState) => {
    const { activeModals } = modals;
    return activeModals.length > 0;
  });
};

export const isActiveModal = (modalName: string): Selector<boolean> => {
  return createSelector(useModals, (modals: IModalsState) => {
    const { activeModals } = modals;
    const existModal = filter(
      activeModals,
      (modal: IModalState) => modal.modalName == modalName
    );
    if (existModal != undefined && existModal.length > 0) {
      return true;
    }
    return false;
  });
};
