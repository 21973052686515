import React, { FunctionComponent, useState, useEffect } from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import { useSelector } from "react-redux";
import { useDimensions } from "@core/store/dimensions/selecters";
import { IResponse } from "./utils";
import RadioButton from "@core/components/RadioButton";
import CheckBox from "@core/components/CheckBox";
import { Fonts } from "@core/utils/fonts";

interface IResponsesList {
  isQCM: boolean;
  font: Fonts;
  initialResponses: Array<IResponse>;
  onUpdateResponses: (responses: Array<IResponse>) => void;
  style?: StyleProp<ViewStyle>;
}

const ResponsesList: FunctionComponent<IResponsesList> = ({
  isQCM = false,
  font = Fonts.ROBOTO_BOLD,
  initialResponses,
  onUpdateResponses,
  style,
}) => {
  const [responses, setResponses] =
    useState<Array<IResponse>>(initialResponses);
  //const [hasChanged, setHasChanged] = useState<boolean>(false);
  const dimensions = useSelector(useDimensions);
  const styles = responsesStyles();
  const Input = isQCM ? CheckBox : RadioButton;

  /*useEffect(() => {
    if (initialResponses != responses) {
      setResponses(initialResponses);
    }
  }, [initialResponses]);*/

  const onValueChange = (checked: boolean, value: number) => {
    if (responses) {
      const newResponses: Array<IResponse> = responses.map(
        (response: IResponse) => {
          if (response.value == value) {
            response.checked = checked;
          }
          if (!isQCM) {
            if (response.value != value) {
              response.checked = false;
            }
          }

          return response;
        }
      );
      setResponses(newResponses);
      onUpdateResponses(newResponses);
    }
  };

  return (
    <View style={[styles.container, style]}>
      <View style={styles.containerInner}>
        {responses &&
          responses.length > 0 &&
          responses.map((response: IResponse, index: number) => {
            return (
              <Input
                style={
                  index != responses.length - 1
                    ? styles.response
                    : styles.responseLast
                }
                key={index}
                label={response.label}
                value={index}
                font={font}
                disabled={!isQCM && response.checked}
                isChecked={response.checked}
                onValueChange={onValueChange}
              />
            );
          })}
      </View>
    </View>
  );
};

const responsesStyles = () => {
  return StyleSheet.create({
    container: {
      alignItems: "center",
      justifyContent: "center",
      maxWidth: 600,
    },
    containerInner: {
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    response: {
      marginBottom: 15,
    },
    responseLast: {
      marginBottom: 0,
    },
  });
};

export default ResponsesList;
