import { StyleSheet } from "react-native";
import { colors } from "@core/utils/colors";
import { Fonts } from "@core/utils/fonts";
import { MQSize, responsiveMargin } from "@core/utils/mq";

const styles = StyleSheet.create({
  modalContent: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  aligner: {
    alignItems: "flex-start",
  },
  spacer:{
    marginBottom:responsiveMargin(MQSize.XSMALL)
  }
});

export default styles;
