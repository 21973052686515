import { IS_DEV } from "@core/config";

export const ERROR_FAILED_TO_FETCH: string = "Failed to fetch";

export interface IMongooseResult {
  success: boolean;
  data?: any;
  error?: {
    message: string;
    code: string;
  };
}

export interface IFetchRequest {
  endpoint: string;
  method?: string;
  body?: BodyInit | string | null;
  token?: string | null;
  bodyEncoded?: boolean;
  path?: string;
}

export interface IFetchResponseError {
  error: string;
  status: number;
}

export const getParams = (
  method: string = "GET",
  body: BodyInit | string | null = null,
  token: string | null = null,
  bodyEncoded: boolean = false
) => {
  const headers = new Headers();
  const params = {
    method,
  } as RequestInit;

  if (body) {
    if (!bodyEncoded) {
      params.body = body;
    } else {
      params.body = new URLSearchParams(JSON.parse(body as string));
    }
  }

  if (token) {
    headers.append("Authorization", "Bearer " + token);
    params.mode = "cors";
    params.credentials = "include";
  }
  params.headers = headers;
  return params;
};

const fetchRequest = async <IFetchResponse>(
  request: IFetchRequest,
  debug: IFetchResponse | null = null
): Promise<IFetchResponse | IFetchResponseError> => {
  const { endpoint, method, body, token, bodyEncoded } = request;

  if (!request.path) {
    throw new Error("You must set a `path`");
  }
  if (debug) {
    return debug;
  }

  try {
    let res = null,
      params = null;
    if (method == "POST" || method == "PUT" || method == "DELETE") {
      const params = getParams(method, body, IS_DEV ? null : token, bodyEncoded);
      res = await fetch(`${request.path}/${endpoint}`, params);
    }

    if (method == "GET") {
      const params = getParams(method, null, IS_DEV ? null : token, bodyEncoded);
      let query = "";
      if (body != null) {
        query = `?${new URLSearchParams(JSON.parse(body.toString() || ""))}`;
      }

      const url = `${request.path}/${endpoint}${query != "" ? query : ""}`;
      res = await fetch(url, params);
    }
    return await res?.json();
  } catch (error) {
    const e = String(error).split("TypeError: ");
    /*switch (e[1]) {
         case ERROR_FAILED_TO_FETCH:
           dispatch(
             errorNotification({
               title: t("api.error.notFound.title"),
               description: t("api.error.notFound.description"),
             })
           );
           break;
       }*/
    return {
      error: e[1],
      status: 401,
    };
  }
};

export { fetchRequest };
