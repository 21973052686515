import * as React from "react";
import Svg, { Path, G, Rect } from "react-native-svg";
import { FunctionComponent } from "react";
import { ISvgIcons } from "@core/components/Svg/types";
import { colors } from "@core/utils/colors";

export const IconCodes: FunctionComponent<ISvgIcons> = ({
  size = 24,
  color = colors.white,
  stroke = colors.lightRed,
}) => {
  return (
    <Svg width={size} height={size} viewBox="-55 -45 240 220">
      <G>
        <G>
          <Path
            d="M93 126H33A33.039 33.039 0 0 1 0 93V33A33.039 33.039 0 0 1 33 0h60a33.039 33.039 0 0 1 33 33 3 3 0 0 1-3 3 3 3 0 0 1-3-3A27.032 27.032 0 0 0 93 6H33A27.032 27.032 0 0 0 6 33v60a27.032 27.032 0 0 0 27 27h60a27.032 27.032 0 0 0 27-27V75a3 3 0 0 1 3-3 3 3 0 0 1 3 3v18a33.039 33.039 0 0 1-33 33Z"
            fill={color}
          />
        </G>
      </G>
      <G fill={color}>
        <G transform="translate(85.768 48.852)">
          <Rect
            width={45.315}
            height={14.618}
            rx={2.004}
            transform="rotate(-45 38.68 16.021)"
          />
          <Path d="M10.339 41.388a7.462 7.462 0 0 1-5.313-2.2l-1.833-1.833a7.468 7.468 0 0 1-2.2-5.313 7.468 7.468 0 0 1 2.2-5.313L26.734 3.191a7.692 7.692 0 0 1 10.624 0l1.832 1.83a7.525 7.525 0 0 1 0 10.626L15.653 39.188a7.463 7.463 0 0 1-5.314 2.2Zm21.708-37.4a4.489 4.489 0 0 0-3.192 1.323L5.314 28.849a4.491 4.491 0 0 0-1.32 3.192 4.486 4.486 0 0 0 1.32 3.192l1.833 1.833a4.625 4.625 0 0 0 6.384 0l23.537-23.541a4.52 4.52 0 0 0 0-6.381l-1.832-1.83a4.478 4.478 0 0 0-3.19-1.324Zm6.084 10.6Z" />
        </G>
        <G>
          <Path d="m122.634 44.029 5.857-5.857a2.436 2.436 0 0 1 3.445 0l6.891 6.89a2.436 2.436 0 0 1 0 3.445l-5.857 5.857a.975.975 0 0 1-1.378.001l-8.958-8.958a.975.975 0 0 1 0-1.378Z" />
          <Path d="M132.28 55.344a4.4 4.4 0 0 1-3.126-1.3l-6.2-6.2a4.407 4.407 0 0 1-1.291-3.129 4.408 4.408 0 0 1 1.291-3.129l1.035-1.032a8.817 8.817 0 0 1 12.457 0 8.818 8.818 0 0 1 0 12.456l-1.031 1.032a4.412 4.412 0 0 1-3.135 1.302Zm-2.067-14.364a5.784 5.784 0 0 0-4.107 1.7l-1.035 1.032a1.434 1.434 0 0 0 0 2.016l6.2 6.2a1.429 1.429 0 0 0 1.007.417 1.424 1.424 0 0 0 1.008-.417l1.031-1.035a5.811 5.811 0 0 0 0-8.214 5.8 5.8 0 0 0-4.104-1.699Zm5.169 10.974Z" />
        </G>
        <G>
          <Path d="m78.534 98.465 11.577-1.653a1.461 1.461 0 0 0 .823-2.481l-8.266-8.268a1.462 1.462 0 0 0-2.482.825Z" />
          <Path d="M78.534 99.965a1.5 1.5 0 0 1-1.062-.438 1.494 1.494 0 0 1-.423-1.275l1.648-11.574a2.962 2.962 0 0 1 2-2.394 2.96 2.96 0 0 1 3.028.717l8.27 8.271a2.939 2.939 0 0 1 .713 3.03 2.946 2.946 0 0 1-2.393 1.995L78.741 99.95a1.241 1.241 0 0 1-.207.015Zm3.072-12.84-1.314 9.573 9.6-1.371Z" />
        </G>
      </G>
      <G>
        <Path
          d="M93 39H33a3 3 0 0 1-3-3 3 3 0 0 1 3-3h60a3 3 0 0 1 3 3 3 3 0 0 1-3 3Z"
          fill={color}
        />
      </G>
      <G>
        <Path
          d="M87 69H33a3 3 0 0 1-3-3 3 3 0 0 1 3-3h54a3 3 0 0 1 3 3 3 3 0 0 1-3 3Z"
          fill={color}
        />
      </G>
      <G>
        <Path
          d="M69 99H33a3 3 0 0 1-3-3 3 3 0 0 1 3-3h36a3 3 0 0 1 3 3 3 3 0 0 1-3 3Z"
          fill={color}
        />
      </G>
    </Svg>
  );
};
