import { StyleSheet } from "react-native";
import { colors } from "../../utils/colors";
import { Fonts } from "../../utils/fonts";
import { FontSizeType, responsiveFontSize } from "../../utils/mq";

export const textStyles = (color: string, type: FontSizeType, size:number | null, font:Fonts) => {
  return StyleSheet.create({
    text: {
      fontFamily: font,
      fontSize: !size ? responsiveFontSize(type) : size,
      color: color,

    },
  });
};
