import React, { FunctionComponent, useState, useEffect } from "react";
import { Text, StyleSheet, TouchableOpacity, ViewStyle } from "react-native";
import { useSelector } from "react-redux";
import { useDimensions } from "../../store/dimensions/selecters";
import { SVGIcon } from "../Svg";
import { colors } from "../../utils/colors";
import { MQSize, FontSizeType, responsiveFontSize } from "../../utils/mq";
import { Fonts } from "../../utils/fonts";

interface IRadioButton {
  label?: string;
  value?: boolean;
  isChecked?: boolean;
  size: MQSize;
  textSize: FontSizeType;
  onValueChange?: Function;
  style?: ViewStyle;
  disabled: boolean;
  font?:Fonts
}

const RadioButton: FunctionComponent<IRadioButton> = ({
  label,
  value,
  isChecked = false,
  size = MQSize.MEDIUM,
  textSize = FontSizeType.SMALL,
  onValueChange,
  disabled = false,
  font = Fonts.ROBOTO_BOLD,
  style,
}) => {
  const styles = radioButtonStyles(textSize, font);

  const dimensions = useSelector(useDimensions);

  const [checked, setChecked] = useState<boolean>(isChecked);

  useEffect(() => {
   /* if (onValueChange) {
      onValueChange(checked, value);
    }*/
  }, [checked])

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked])


  return (
    <>
      <TouchableOpacity
        disabled={disabled}
        style={[styles.checkbox, style]}
        onPress={() => {
          onValueChange && onValueChange(!checked, value);
          if(!checked){
            setChecked(true)
          }
          
        }}
      >
        {!checked && (
          <SVGIcon
            name={"RadioButton"}
            size={size}
            backgroundColor={colors.transparent}
          />
        )}

        {checked && (
          <SVGIcon
            name={"RadioButtonChecked"}
            size={size}
            backgroundColor={colors.transparent}
          />
        )}

        {label != "" && <Text style={styles.label}>{label}</Text>}
      </TouchableOpacity>
    </>
  );
};

const radioButtonStyles = (size: FontSizeType, font:Fonts = Fonts.ROBOTO_BOLD) => {
  return StyleSheet.create({
    checkbox: {
      alignItems: "center",
      flexDirection: "row",
    },
    label: {
      textAlign: "left",
      marginLeft: 10,
      color: colors.white,
      fontFamily: font,
      fontSize: responsiveFontSize(size),
    },
  });
};

export default RadioButton;
