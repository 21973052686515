import React, { FunctionComponent } from "react";
import { ViewStyle, Animated, StyleProp } from "react-native";
import { textStyles } from "./styles";
import { FontSizeType } from "../../utils/mq";
import { colors } from "../../utils/colors";
import { MQSize } from "../../utils/mq";
import { TextStyle } from "pixi.js";
import { Fonts } from "@core/utils/fonts";

interface ITextTitle {
  text: string | null;
  color?: string;
  type?: FontSizeType;
  font?:Fonts;
  size?: number | null;
  style?: ViewStyle;
}

const TextTitle: FunctionComponent<ITextTitle> = ({
  text = "",
  color = colors.white,
  type = FontSizeType.MEDIUM,
  font = Fonts.ROBOTO_BOLD,
  size = null,
  style
}) => {
  const styles = textStyles(color, type, size, font);

  return (
    <Animated.Text adjustsFontSizeToFit={true} style={[styles.text, style]}>{text}</Animated.Text>
  );
};

export default TextTitle;
