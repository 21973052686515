/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react";
import { AppWrapper } from "@core/components";
import Navigation from "./navigation";
import { store } from "./store"
import * as modals from "./components/Modals"
import { AppIconsList } from "./components/Svg"


export default function App() {
  return (
    <AppWrapper
      languages={{
        fr: require("./languages/fr.ts").default,
      }}
      store={store}
      modals={modals}
      icons={AppIconsList}
    >
      <Navigation />
    </AppWrapper>
  );
}