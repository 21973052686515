import * as React from "react";
import Svg, { Path, G } from "react-native-svg";
import { FunctionComponent } from "react";
import { ISvgIcons } from "@core/components/Svg/types";
import { colors } from "@core/utils/colors";

export const IconGift: FunctionComponent<ISvgIcons> = ({
  size = 24,
  color = colors.white,
  stroke = colors.lightRed,
}) => {
  return (
    <Svg width={size} height={size} viewBox="-40 -40 240 240">
      <Path
        d="M154.734 16.478 93.263.298a9.011 9.011 0 0 0-11 6.412l-.351 1.336-20.159-.8a8.98 8.98 0 0 0-6.463 2.38 8.934 8.934 0 0 0-2.888 6.253l-.12 2.994-18.448 3.373a8.937 8.937 0 0 0-5.783 3.739 8.913 8.913 0 0 0-1.5 3.826L5.101 40.118a8.954 8.954 0 0 0-4.6 5.132 8.956 8.956 0 0 0 .381 6.879l45.279 94.236a9.015 9.015 0 0 0 8.127 5.1 8.942 8.942 0 0 0 3.883-.887l27.563-13.245 27.552-5.038 10.246 2.7a9.044 9.044 0 0 0 2.306.3 8.95 8.95 0 0 0 4.52-1.229 8.946 8.946 0 0 0 4.17-5.484l26.609-101.1a9.01 9.01 0 0 0-6.403-11.004Zm-95.369-2.447a2.985 2.985 0 0 1 2.153-.793l18.834.751-24.7 93.828a9.01 9.01 0 0 0 6.413 10.994l22.779 6-27.743-1.108a2.979 2.979 0 0 1-2.083-.962 2.981 2.981 0 0 1-.793-2.155L58.396 16.12a2.979 2.979 0 0 1 .968-2.089Zm28.111 116.885-32.667 5.974a2.992 2.992 0 0 1-2.244-.483 2.98 2.98 0 0 1-1.247-1.927l-1.621-8.872a8.986 8.986 0 0 0 7.172 4.085ZM32.993 29.388a2.983 2.983 0 0 1 1.927-1.246l17.122-3.131-3.718 93.1-15.815-86.473a2.984 2.984 0 0 1 .484-2.25ZM6.174 47.232a2.98 2.98 0 0 1 1.531-1.71l19.529-9.383 16.816 91.955-37.748-78.57a2.964 2.964 0 0 1-.128-2.29Zm49.406 97.934a3.006 3.006 0 0 1-4-1.4l-.731-1.523a8.882 8.882 0 0 0 3.4.7 9.241 9.241 0 0 0 1.635-.147l7.488-1.37Zm99.762-119.223-26.609 101.1a2.983 2.983 0 0 1-1.39 1.828 2.992 2.992 0 0 1-2.276.311l-61.471-16.179a3.006 3.006 0 0 1-2.138-3.665L88.069 8.233a3.006 3.006 0 0 1 2.9-2.237 2.975 2.975 0 0 1 .765.1l61.469 16.179a2.979 2.979 0 0 1 1.828 1.389 2.969 2.969 0 0 1 .312 2.281Z"
        fill={color}
      />
    </Svg>
  );
};
