import {
  getLinkingActivationCode,
} from "@core/navigation/options";
import { AppDispatch, RootState } from "@core/store";
import { ScreenName } from "@core/store/navigation/types";
import { getPathFromState, getStateFromPath } from "@core/navigation/options";

export const getLinking = (
  screens: ScreenName[],
  dispatch: AppDispatch,
  state: RootState
) => {
  const linking = getLinkingActivationCode(screens, dispatch, state);

  return {
    ...linking,
    getPathFromState: (routesState: any, opts: any) => {
      const _getPathFromState = linking.getPathFromState
        ? linking.getPathFromState
        : getPathFromState;
      return _getPathFromState(routesState, opts, dispatch);
    },

    getStateFromPath: (path: string, opts: any) => {
      const _getStateFromPath = linking.getStateFromPath
        ? linking.getStateFromPath
        : getStateFromPath;
      return _getStateFromPath(path, opts);
    },
  };
};